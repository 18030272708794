@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply text-sm md:text-base lg:text-lg;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cairo', sans-serif;
  font-family: 'Yantramanav', sans-serif;
}

ul {
  list-style-position: inside;
}

body {
  @apply bg-gray-800;
  overflow: overlay;
}

#app {
  height: 100vh;
}

#content {
  width: 100vw;
}

.btn {
  @apply bg-gray-100 rounded-lg border border-gray-100 my-1 px-6 py-1 shadow-md
}

#mint-btn {
  @apply text-3xl px-24 py-2
}

.btn:hover {
  @apply border-gray-300 bg-gray-200
}

.card {
  @apply m-4 p-4 bg-gray-200 max-w-3xl rounded-lg shadow-md
}

img.gallery-img {
  @apply h-full w-full;
  border: 2px solid;
  border-color: #000000;
}